import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"


export default function IrisGPTChatbotInZendeskHelpCenterGuide() {
  return (
    <Layout
      keywords="Zendesk help center, integrate AI chatbot"
      title="Set up IrisGPT AI chatbot on your Zendesk help center page"
      description="Documentation on how to integrate IrisAgent AI chatbot on your Zendesk help center page"
    >
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Documentation for IrisGPT Chatbot on Zendesk Help Center</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>IrisGPT Chatbot on Zendesk Help Center</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Integrating IrisGPT Chatbot on your Zendesk Help Center</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's IrisGPT Chatbot can power search and Q&A engine on your Zendesk Help Center to deflect customer queries and reduce the number of tickets handled by support agents.
                    <br/>
                    <br/>
                    <h2>
                    Installation Guide
                    </h2>
                    <br/>
                <ol>
                    <li>
                        Navigate to the <b>Customize Design</b> tab in your Zendesk Help Center. This can be found under
                        Zendesk Guide > Guide Admin > Customize Design.
                    </li>
                    <li>
                        Edit your theme. You can either add a new theme by selecting <b>Add theme</b> and making a copy
                        of your Live theme by selecting the three dots > Copy.
                        If you would like to edit your existing theme directly, click on <b>Customize</b> under your
                        Live theme.
                        <br/><img
                        src="/img/zd-help-theme.png"
                        style={{
                            width: "80%",
                            padding: "5%",
                        }}
                        alt="SSO"
                    /><br/>

                    </li>
                    <li>
                        In order to add the IrisGPT Chatbot to your Zendesk Help Center, you will need to edit the code.
                        After clicking on the <b>Customize</b> button in the previous step, Click <b>Edit Code</b> in
                        the bottom right corner:
                        <br/><img
                        src="/img/zd-help-edit-code.png"
                        style={{
                            width: "80%",
                            padding: "5%",
                        }}
                        alt="SSO"
                    /><br/>

                    </li>
                    <li>
                        Navigate to the <b>templates</b> on the left hand side, click on the footer (footer.hbs) file.
                    </li>
                    <li>
                        Within <b>footer.hbs</b> file, add the code snippet from <a
                        href={"https://web.irisagent.com/manage-chatbot"}
                        target={"_blank"}>https://web.irisagent.com/manage-chatbot</a> just before the
                        closing <code>{`</footer>`}</code> tag

                    </li>
                    <li>
                        Note: You can also manage your IrisGPT chatbot settings from <a
                        href={"https://web.irisagent.com/manage-chatbot"}
                        target={"_blank"}>https://web.irisagent.com/manage-chatbot</a>
                    </li>
                    <li>
                        Save your work. Click <b>Save</b>.
                    </li>
                    <li>
                        If you have Zendesk Messaging Web Widget enabled for your Zendesk account, the messaging widget
                        may block the IrisGPT Chatbot's visibility on the help center pages.
                        To fix this, you can disable the messaging widget from the Zendesk Admin settings.
                        <ol>
                            <li>
                                Navigate to Zendesk Support Admin Center
                            </li>
                            <li>
                                On the left hand side, please find the following section and click on
                                it: <b>Channels</b> > <b>Messaging and Social</b> > <b>Messaging</b>
                            </li>
                            <li>
                                If you do not have any channel of type <b>Web Widget</b>, you may ignore the below steps
                            </li>
                            <li>
                                Edit the Web Widget channel settings and scroll down to the <b>Installation</b> section
                            </li>
                            <li>
                                Ensure that the checkbox named <b>Automatically embed Web Widget in your help
                                center</b> is unselected
                            </li>
                            <li>
                                Save the changes. Click <b>Save</b>.
                            </li>
                        </ol>
                    </li>
                    <li>
                        [optional] You can click on the preview button on the left side of the editor to see how the
                        layout looks before deploying it.
                    </li>
                    <li>
                        That’s it, done! You should be seeing the IrisGPT Chatbot on your help center pages at bottom
                        right.
                    </li>
                </ol>


            </div>
          </div>
      </section>

    </Layout>
  )
}
